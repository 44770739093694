import {legacy_createStore as createStore, applyMiddleware} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import setAuthToken from './utils/setAuthToken';


const initialState = {}

const middleware =[thunk]

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

let currentState = store.getState();

store.subscribe(() => {
  // keep track of the previous and current state to compare changes
  let previousState = currentState;
  currentState = store.getState();
  // if the token changes set the value in localStorage and axios headers
  if (previousState.auth.token !== currentState.auth.token) {
    const token = currentState.auth.token;
    setAuthToken(token);
  }
});

// let isAuthenticatedChecked = false;

// store.subscribe(() => {
//   const currentState = store.getState();

//   if (!isAuthenticatedChecked) {
//     if (currentState.auth.isAuthenticated) {
//       const token = currentState.auth.token;
//       setAuthToken(token);
//     }
//     isAuthenticatedChecked = true;
//   }
// });

export default store;